export const achievementData = {
  bio: "Öğrenme, yaşamak gibi bir şeydir; ne kadar çok öğrenirsen, o kadar çok yaşarsın. İnsanlar bilgiye ne kadar aç olursa, kendilerini keşfetme ve geliştirme yolunda o kadar çok adım atarlar. Her yeni bilgi, hayatın derinliklerine inme fırsatıdır ve her adım, bizi daha iyi bir insan yapma yolunda bir fırsattır. - Cemil Meriç",
  achievements: [
    {
      id: 1,
      title: "Patika - Frontend",
      details: "Başlangıç Seviye Frontend Web Development Patikası",
      date: "Mart 31, 2022",
      field: "Coding",
      image:
        "https://patika-prod.s3-eu-central-1.amazonaws.com/certificates/QQSH9mb",
    },
    
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
