import resume from '../assets/pdf/resume.pdf'
import profile from '../assets/png/profile.png'

export const headerData = {
    name: 'Osman HORASAN',
    title: "Web Geliştirici",
    desciption:"Hayat, bir yolculuğun kendisidir; varış noktası değil. Her adımda keşfetmek, öğrenmek ve büyümek önemlidir. Yolun tadını çıkarın, çünkü bu deneyim yalnızca bir kez yaşanır.",
    image: profile,
    resumePdf: resume
}
