export const experienceData = [
    {
        id: 1,
        company: 'Kayraexport',
        jobtitle: 'Frontend Developer',
        startYear: 'Mayıs 2022',
        endYear: 'Mayıs 2024',
        description: 'Kayraexport\'ta E-Ticaret Platformu Geliştiricisi olarak 2 yıl çalıştım. Geliştirme süreçlerini yönetmek ve e-ticaret platformlarını optimize etmekle sorumluydum. HTML, CSS ve JavaScript teknolojilerini kullanarak web uygulamalarının geliştirilmesini sağladım.'
    },
    
    {
        id: 2,
        company: 'Hediye Rengi',
        jobtitle: 'Yazılım Destek Personeli',
        startYear: 'Şubat 2019',
        endYear: 'Mayıs 2019',
        description: 'Hediye Rengi\'nde Yazılım Destek Personeli olarak çalıştım. E-ticaret platformunun kullanıcı arayüzü geliştirme ve iyileştirme süreçlerini yöneterek, kullanıcı deneyimini optimize ettim. HTML, CSS ve JavaScript teknolojilerini kullanarak geliştirmeler yaptım.'
    },
    {
        id: 3,
        company: 'Erzincan Üniversitesi',
        jobtitle: 'Proje Yöneticisi',
        startYear: 'Eylül 2017',
        endYear: 'Mayıs 2018',
        description: 'Eğitim sektöründe, Proje Geliştirme Dersi kapsamında grup ve proje yönetimi sorumluluklarını üstlendim. Öğrencilerle iş birliği yaparak yazılım projelerinin planlanmasını ve yönetilmesini sağladım.'
    },
    {
        id: 4,
        company: 'Tek Bilgisayar Bilişim Sistemleri',
        jobtitle: 'Bilgi İşlem Stajyeri',
        startYear: 'Eylül 2012',
        endYear: 'Haziran 2013',
        description: 'Bilişim sektöründe temel iş süreçleri ve teknolojik altyapılar hakkında pratik deneyim kazandım. Donanım ve yazılım alanında çalışarak bilgisayar sistemlerini bakım ve güncelleme işlemlerini gerçekleştirdim. Teknik destek ve sorun giderme süreçlerine katkıda bulundum.'
    }
];
