export const educationData = [
    {
        id: 1,
        institution: 'Erzincan Üniversitesi',
        course: 'Bilgisayar ve Öğretim Teknolojileri Eğitimi (BÖTE)',
        startYear: '2014',
        endYear: '2018'
    },
    {
        id: 2,
        institution: 'Halil İlik Mesleki ve Teknik Anadolu Lisesi',
        course: 'Web Programlama',
        startYear: '2009',
        endYear: '2012'
    },
    
]