import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import bodydesigner from '../assets/svg/projects/bodydesigner.png'
import gucluklima from '../assets/svg/projects/gucluklima.png'
import ozdinlermetalhurda from '../assets/svg/projects/ozdinlermetalhurda.png'
import ercelikyapimalzemeleri from '../assets/svg/projects/ercelikyapimalzemeleri.png'



export const projectsData = [

    {
        id: 1,
        projectName: 'Bodydesigner',
        projectDesc: 'Spor Eğitim ve Video Platformu',
        tags: ['Eğitim', 'Php - Mysql'],
        // code: 'https://github.com/yourusername/elonky',
        demo: 'https://bodydesigner.online/index.php',
        image: bodydesigner
    },
    {
        id: 2,
        projectName: 'Güçlü Klima',
        projectDesc: 'İstanbul ve civarı klima bakım onarım.',
        tags: ['Web - Klima', 'HTML - JS'],
        // code: 'https://github.com/yourusername/audi-dictionary',
        demo: 'https://www.gucluklima.com/',
        image: gucluklima
    },
    {
        id: 3,
        projectName: 'Özdinler Metal Hurda',
        projectDesc: 'Hurda ve geri dönüşüm yenilik çi çözümler',
        tags: ['Web - Eğitim', 'PHP - AJAX - MYSQL'],
        // code: 'https://github.com/yourusername/sharable-knowledge-network',
        demo: 'https://ozdinlermetalhurda.com/',
        image: ozdinlermetalhurda
    },
   
    {
        id: 4,
        projectName: 'Erçelik Yapi Malzemeleri',
        projectDesc: 'Bu projede, 11. Ulusal Bilgisayar Eğitim Öğrencileri Kongresi için web tasarımı yapıldı. Proje, kongrenin web sitesinin tasarım ve işlevselliği üzerine odaklandı ve bu alanda 3.ödülü kazandı.',
        tags: ['Web - Eğitim', 'HTML - JS'],
        // code: 'https://github.com/yourusername/national-computer-education',
        demo: 'https://www.ercelikyapimalzemeleri.com/',
        image: ercelikyapimalzemeleri
    },
 
];



// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/